export const postsFeedPageMockData = {
  response: {
    postFeedPage: {
      posts: {
        posts: [
          {
            coverMedia: {
              custom: false,
              displayed: true,
              enabled: true,
              image: {
                height: 1216,
                id: '84b06e_cc42e348006f4ee0957807b0fe5a56db~mv2.png',
                url: 'https://static.wixstatic.com/media/84b06e_cc42e348006f4ee0957807b0fe5a56db~mv2.png',
                width: 1840,
              },
            },
            excerpt:
              'An eCommerce website allows you to retail your products 24/7. Learn how to make a website to sell.',
            id: 'd0dcf88c-e4ff-48a8-bc9b-50c9fef60761',
            language: 'en',
            lastPublishedDate: '2024-01-04T09:35:53.681Z',
            media: {
              wixMedia: {
                image: {
                  height: 1216,
                  id: '84b06e_cc42e348006f4ee0957807b0fe5a56db~mv2.png',
                  url: 'https://static.wixstatic.com/media/84b06e_cc42e348006f4ee0957807b0fe5a56db~mv2.png',
                  width: 1840,
                },
              },
            },
            minutesToRead: 11,
            owner: {
              activityStatus: 'UNKNOWN',
              appStore: {
                '14bcded7-0066-7c35-14d7-466cb3f09103': {
                  permissions: {
                    role: 'editor',
                  },
                },
              },
              contactId: '6d12aa86-279d-4f31-b596-d25bb7d84827',
              id: '6d12aa86-279d-4f31-b596-d25bb7d84827',
              image: {
                height: 0,
                id: '',
                url: 'https://lh3.googleusercontent.com/a/ACg8ocJwh2LISkPuweMK2Kc3ZwrJ6NBTeU5usMnClVQmrZhLxg%3Ds96-c',
                width: 0,
              },
              isBlocked: false,
              isOwner: false,
              name: 'Rachel Bistricer',
              privacyStatus: 'UNKNOWN',
              siteMemberId: '6d12aa86-279d-4f31-b596-d25bb7d84827',
              slug: 'rachelbi',
              status: 'UNKNOWN',
            },
            pinned: false,
            preview: false,
            pricingPlanIds: [],
            relatedPostIds: [
              'e03e6720-b960-4252-b257-338a21d7e0d9',
              'b866e869-2138-4120-9c4a-ede1c409c176',
              '2e2f07e2-9e3b-4260-867c-e54900cf766e',
            ],
            slug: 'how-to-make-a-website-to-sell',
            tagIds: [],
            title: 'How to make a website to sell just about anything',
            translations: [],
            url: {
              base: 'https://www.wix.com/blog',
              path: '/how-to-make-a-website-to-sell',
            },
          },
          {
            coverMedia: {
              custom: false,
              displayed: true,
              enabled: true,
              image: {
                height: 608,
                id: '4a5235_98f23079881c4f1f9112590450e9445c~mv2.png',
                url: 'https://static.wixstatic.com/media/4a5235_98f23079881c4f1f9112590450e9445c~mv2.png',
                width: 920,
              },
            },
            customExcerpt: true,
            excerpt:
              'Here are the 20 best online business ideas to help you become professionally independent.',
            featured: false,
            firstPublishedDate: '2024-01-04T06:25:59.795Z',
            hashtags: [],
            id: '2e2f07e2-9e3b-4260-867c-e54900cf766e',
            internalCategoryIds: [
              '64412d13e343024bdbb0d929',
              '643d3218a8ca65b990b0a36d',
              '643d3218a8ca65b990b0a36f',
            ],
            internalId: '644129b69fa61a75c31ef67e',
            internalRelatedPostIds: [],
            isLiked: false,
            isSubscribed: false,
            language: 'en',
            lastPublishedDate: '2024-01-04T06:25:59.795Z',
            media: {
              custom: false,
              displayed: true,
              wixMedia: {
                image: {
                  height: 608,
                  id: '4a5235_98f23079881c4f1f9112590450e9445c~mv2.png',
                  url: 'https://static.wixstatic.com/media/4a5235_98f23079881c4f1f9112590450e9445c~mv2.png',
                  width: 920,
                },
              },
            },
            memberId: 'd63bc953-96af-4802-8c00-64a58aed32f6',
            minutesToRead: 14,
            mostRecentContributorId: '2f9359f2-9b16-4cca-af97-c3b3386a5c39',
            owner: {
              activityStatus: 'UNKNOWN',
              appStore: {
                '14bcded7-0066-7c35-14d7-466cb3f09103': {
                  permissions: {
                    role: 'writer',
                  },
                },
              },
              contactId: 'd63bc953-96af-4802-8c00-64a58aed32f6',
              id: 'd63bc953-96af-4802-8c00-64a58aed32f6',
              image: {
                height: 0,
                id: '4a5235_a3504a955d49481e967887a3ee676125%7Emv2.jpg',
                url: 'https://static.wixstatic.com/media/4a5235_a3504a955d49481e967887a3ee676125%7Emv2.jpg',
                width: 0,
              },
              isBlocked: false,
              isOwner: false,
              name: 'Cecilia Lazzaro Blasbalg',
              privacyStatus: 'UNKNOWN',
              siteMemberId: 'd63bc953-96af-4802-8c00-64a58aed32f6',
              slug: 'cecilia-lazzaro-blasbalg',
              status: 'UNKNOWN',
            },
            pinned: false,
            preview: false,
            pricingPlanIds: [],
            relatedPostIds: [],
            slug: 'online-business-ideas',
            tagIds: [],
            title: 'Top 22 online business ideas to start in 2024',
            translations: [],
            url: {
              base: 'https://www.wix.com/blog',
              path: '/online-business-ideas',
            },
          },
          {
            coverMedia: {
              custom: false,
              displayed: true,
              enabled: true,
              image: {
                height: 608,
                id: 'ea6ac8_251ef5c409254af584a7a5481f19b4f6~mv2.png',
                url: 'https://static.wixstatic.com/media/ea6ac8_251ef5c409254af584a7a5481f19b4f6~mv2.png',
                width: 1064,
              },
            },
            customExcerpt: true,
            excerpt:
              'In honor of Pride Month, we’ve compiled 7 actionable tips for making an LGBTQ+ friendly website We’ll also go over why it’s important for yo',
            featured: false,
            firstPublishedDate: '2024-01-02T21:02:42.310Z',
            hashtags: [],
            id: 'e8bb1939-7151-4654-b3a2-07428c258d3a',
            internalCategoryIds: ['64412d04e343024bdbb0d928'],
            internalId: '644129b39fa61a75c31ef520',
            isLiked: false,
            isSubscribed: false,
            language: 'en',
            lastPublishedDate: '2024-01-02T21:02:42.310Z',
            media: {
              custom: false,
              displayed: true,
              wixMedia: {
                image: {
                  height: 608,
                  id: 'ea6ac8_251ef5c409254af584a7a5481f19b4f6~mv2.png',
                  url: 'https://static.wixstatic.com/media/ea6ac8_251ef5c409254af584a7a5481f19b4f6~mv2.png',
                  width: 1064,
                },
              },
            },
            memberId: '06cac603-b5b8-4fca-96e6-970c3c2dd3c7',
            minutesToRead: 7,
            mostRecentContributorId: '6d12aa86-279d-4f31-b596-d25bb7d84827',
            owner: {
              activityStatus: 'UNKNOWN',
              appStore: {
                '14bcded7-0066-7c35-14d7-466cb3f09103': {
                  permissions: {
                    role: 'writer',
                  },
                },
              },
              contactId: '06cac603-b5b8-4fca-96e6-970c3c2dd3c7',
              id: '06cac603-b5b8-4fca-96e6-970c3c2dd3c7',
              image: {
                height: 0,
                id: '4a5235_a053afef213e4551b40e70a2f22d18e2%7Emv2.jpg',
                url: 'https://static.wixstatic.com/media/4a5235_a053afef213e4551b40e70a2f22d18e2%7Emv2.jpg',
                width: 0,
              },
              isBlocked: false,
              isOwner: false,
              name: 'Jenna Romano',
              privacyStatus: 'UNKNOWN',
              siteMemberId: '06cac603-b5b8-4fca-96e6-970c3c2dd3c7',
              slug: 'romano-jenna',
              status: 'UNKNOWN',
            },
            slug: 'lgbtq-friendly-website',
            tagIds: [],
            title:
              'Design with pride: 7 tips for making an LGBTQ+ friendly website ',
            translations: [],
            url: {
              base: 'https://www.wix.com/blog',
              path: '/lgbtq-friendly-website',
            },
          },
          {
            coverMedia: {
              custom: true,
              displayed: true,
              enabled: true,
              image: {
                height: 608,
                id: 'ea6ac8_793481be6f9a4c5c8674839bd73129a3~mv2.jpg',
                url: 'https://static.wixstatic.com/media/ea6ac8_793481be6f9a4c5c8674839bd73129a3~mv2.jpg',
                width: 1064,
              },
            },
            customExcerpt: true,
            excerpt:
              "Sitting down with design experts, we've rounded up 9 new web trends for 2024.\n",
            featured: false,
            firstPublishedDate: '2024-01-02T18:11:38.985Z',
            hashtags: [],
            id: '39acf147-f371-4254-ab7d-6873ffcdf595',
            isLiked: false,
            isSubscribed: false,
            language: 'en',
            lastPublishedDate: '2024-01-02T18:11:38.985Z',
            media: {
              custom: true,
              displayed: true,
              wixMedia: {
                image: {
                  height: 608,
                  id: 'ea6ac8_793481be6f9a4c5c8674839bd73129a3~mv2.jpg',
                  url: 'https://static.wixstatic.com/media/ea6ac8_793481be6f9a4c5c8674839bd73129a3~mv2.jpg',
                  width: 1064,
                },
              },
            },
            owner: {
              activityStatus: 'UNKNOWN',
              appStore: {
                '14bcded7-0066-7c35-14d7-466cb3f09103': {
                  permissions: {
                    role: 'writer',
                  },
                },
              },
              contactId: '06cac603-b5b8-4fca-96e6-970c3c2dd3c7',
              id: '06cac603-b5b8-4fca-96e6-970c3c2dd3c7',
              image: {
                height: 0,
                id: '4a5235_a053afef213e4551b40e70a2f22d18e2%7Emv2.jpg',
                url: 'https://static.wixstatic.com/media/4a5235_a053afef213e4551b40e70a2f22d18e2%7Emv2.jpg',
                width: 0,
              },
              isBlocked: false,
              isOwner: false,
              name: 'Jenna Romano',
              privacyStatus: 'UNKNOWN',
              siteMemberId: '06cac603-b5b8-4fca-96e6-970c3c2dd3c7',
              slug: 'romano-jenna',
              status: 'UNKNOWN',
            },
            slug: 'web-design-trends',
            tagIds: [],
            title: 'The 9 biggest web design trends of 2024',
            translations: [],
            url: {
              base: 'https://www.wix.com/blog',
              path: '/web-design-trends',
            },
          },
        ],
      },
      settings: {},
      tags: [],
      translations: {},
    },
  },
};
