import { Category } from '@wix/answers-api';
import { Box, Button, Heading, Text } from '@wix/design-system';
import Image from 'next/image';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent } from 'react';
import { ArrowRight } from 'wix-ui-icons-common';
import { ArrowDownRightIcon } from '../../../icons/ArrowDownRightIcon';
import { InnerLink } from '../../InnerLink';
import { DATA_HOOKS } from './../../../dataHooks';
import css from './index.module.scss';
import { TOOLTIP_ENTER_DELAY } from '../../../constants';
import {
  ItemOnPageEventSource,
  useBiOnVisibleItem,
} from '../../../hooks/useBiOnVisibleItem';

export type TopicsSectionCardProps = {
  title: string;
  url: string;
  imageSrc: string;
  childTopics: Category[] | undefined;
  withOnViewEvent?: boolean;
  onClickShowAll: (isTitleClicked: boolean) => void;
  onClickChildTopic: (topic: Category, itemOrder: number) => void;
};

export const LIMITED_TOPICS_IN_CARD = 3;

export const TopicsSectionCard: FunctionComponent<TopicsSectionCardProps> = ({
  title,
  url,
  imageSrc,
  childTopics,
  withOnViewEvent,
  onClickShowAll,
  onClickChildTopic,
}) => {
  const { t } = useTranslation();
  const { itemRef } = useBiOnVisibleItem(ItemOnPageEventSource.TOPICS_SECTION);

  const limitedChildTopics =
    childTopics && childTopics.slice(0, LIMITED_TOPICS_IN_CARD);

  return (
    <Box
      direction="vertical"
      alignContent="start"
      className={css.TopicsSectionCardWrapper}
      dataHook={DATA_HOOKS.TOPICS_SECTION_CARD}
    >
      <Box
        dataHook={DATA_HOOKS.TOPICS_SECTION_CARD_IMAGE}
        className={css.TopicsSectionCardImage}
      >
        <Image
          alt={t('homepage.topics-section.image-alt', { title })}
          src={imageSrc}
          loading="lazy"
          fill
        />
      </Box>
      <Box
        direction="vertical"
        className={css.TopicsSectionCardTopicsWrapper}
        align="left"
      >
        <InnerLink
          onClick={() => onClickShowAll(true)}
          href={url}
          className={css.TopicsSectionCardTitleLink}
          dataHook={DATA_HOOKS.TOPICS_SECTION_CARD_TITLE_LINK}
        >
          <Heading
            appearance="H3"
            className={css.TopicsSectionCardTitle}
            dataHook={DATA_HOOKS.TOPICS_SECTION_CARD_TITLE}
            ellipsis
          >
            {title}
          </Heading>
        </InnerLink>
        {withOnViewEvent ? <div ref={itemRef} /> : null}
        <Box
          direction="vertical"
          align="left"
          className={css.TopicsSectionCardTopics}
        >
          {limitedChildTopics &&
            limitedChildTopics.map((topic, idx) => (
              <InnerLink
                onClick={() => onClickChildTopic(topic, idx + 1)}
                href={topic.uri}
                key={`${topic.id}-text-button`}
                className={css.TopicsSectionCardTopicLink}
                dataHook={DATA_HOOKS.TOPICS_SECTION_CARD_CHILD_TOPIC}
              >
                <ArrowDownRightIcon />
                <Text
                  ellipsis
                  tooltipProps={{ enterDelay: TOOLTIP_ENTER_DELAY }}
                  className={css.TopicsSectionCardTopicText}
                >
                  {topic.name}
                </Text>
              </InnerLink>
            ))}
        </Box>
        <InnerLink
          onClick={() => onClickShowAll(false)}
          href={url}
          className={css.TopicsSectionCardShowAllLink}
          dataHook={DATA_HOOKS.TOPICS_SECTION_CARD_SHOW_ALL}
        >
          <Button
            priority="secondary"
            size="medium"
            skin="dark"
            className={css.TopicsSectionCardShowAllButton}
            suffixIcon={<ArrowRight />}
          >
            {t('homepage.topics-section.show-all')}
          </Button>
        </InnerLink>
      </Box>
    </Box>
  );
};
