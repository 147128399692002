import { Box, Text, TextButton } from '@wix/design-system';
import { FunctionComponent } from 'react';
import { ArrowRightSmall } from 'wix-ui-icons-common';
import { DATA_HOOKS } from '../../../dataHooks';
import css from './index.module.scss';
import { useBiOnVisibleItem, ItemOnPageEventSource } from '../../../hooks/useBiOnVisibleItem';

export type ExploreMoreResourcesItemProps = {
  title: string;
  description: string;
  cta: string;
  url: string;
  withOnViewEvent?: boolean;
  onClick: () => void;
};

export const ExploreMoreResourcesItem: FunctionComponent<
  ExploreMoreResourcesItemProps
> = ({ title, description, cta, url, withOnViewEvent, onClick }) => {
  const { itemRef } = useBiOnVisibleItem(ItemOnPageEventSource.EXPLORE_MORE_RESOURCES_SECTION);

  return (
    <Box
      direction="vertical"
      align="left"
      verticalAlign="top"
      className={css.exploreMoreResourcesItemWrapper}
      dataHook={DATA_HOOKS.EXPLORE_MORE_RESOURCES_ITEM}
    >
      <TextButton
        as="a"
        target="_blank"
        skin="dark"
        dataHook={DATA_HOOKS.EXPLORE_MORE_RESOURCES_ITEM_TITLE}
        className={css.exploreMoreResourcesItemTitle}
        onClick={onClick}
        href={url}
      >
        {title}
      </TextButton>
      {withOnViewEvent ? <div ref={itemRef} className={css.refWrapper} /> : null}

      <Text
        heading="H4"
        size="medium"
        dataHook={DATA_HOOKS.EXPLORE_MORE_RESOURCES_ITEM_DESCRIPTION}
        className={css.exploreMoreResourcesItemDescription}
      >
        {description}
      </Text>
      <Box
        className={css.exploreMoreResourcesItemCtaWrapper}
        verticalAlign="bottom"
      >
        <TextButton
          target="_blank"
          as="a"
          skin="dark"
          onClick={onClick}
          href={url}
          className={css.exploreMoreResourcesItemCta}
          dataHook={DATA_HOOKS.EXPLORE_MORE_RESOURCES_ITEM_CTA}
          suffixIcon={<ArrowRightSmall />}
          underline="always"
          weight="normal"
        >
          {cta}
        </TextButton>
      </Box>
    </Box>
  );
};
