import { Article, Category } from '@wix/answers-api';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { Box, Divider, Heading, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FunctionComponent, useContext } from 'react';
import { BI, MAX_MOBILE_WIDTH, TRENDING_ARTICLES_NUM_ITEMS } from '../../constants';
import { Context } from '../../context';
import { DATA_HOOKS } from '../../dataHooks';
import { useBI } from '../../hooks/useBI';
import { ItemOnPageEventSource, useBiOnVisibleItem } from '../../hooks/useBiOnVisibleItem';
import { getCategoriesPaths } from '../../utils/categories';
import { TrendingArticleItem } from './TrendingArticleItem';
import css from './index.module.scss';

export type TrendingArticlesProps = {
  articles: Article[];
};

export const TrendingArticles: FunctionComponent<TrendingArticlesProps> = ({
  articles
}) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();
  const { itemRef } = useBiOnVisibleItem(ItemOnPageEventSource.TRENDING_ARTICLES);

  const limitedArticles = articles.slice(0, TRENDING_ARTICLES_NUM_ITEMS);
  const TRENDING_ARTICLES_BACKGROUND =
    'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/trending-articles/trending-articles-new.jpg';

  const { categoriesTree } = useContext(Context);
  const categoriesPaths = getCategoriesPaths(
    categoriesTree,
    articles.map((article) => article.categoryId)
  );

  const { TRENDING_TOPICS_ARTICLE, TRENDING_TOPICS_CATEGORY } =
    BI.CLICKED_ITEM_TYPES;
  const onArticleOrCategoryClick = async (
    url: string,
    title: string,
    itemId: string,
    order: number,
    clickedItemType:
      | typeof TRENDING_TOPICS_ARTICLE
      | typeof TRENDING_TOPICS_CATEGORY
  ) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.HOME,
        kb_lang: locale,
        clicked_item_type: clickedItemType,
        clicked_url: url,
        clicked_text: title,
        clicked_item_id: itemId,
        clicked_item_order: `${order}`,
      })
    );
  };

  const onArticleClick = async (article: Article, order: number) =>
    onArticleOrCategoryClick(
      article.url,
      article.title,
      article.id,
      order,
      TRENDING_TOPICS_ARTICLE
    );

  const onArticleCategoryClick = async (category: Category, order: number) =>
    onArticleOrCategoryClick(
      category.url,
      category.name,
      category.id,
      order,
      TRENDING_TOPICS_CATEGORY
    );

  return (
    <Box
      direction="vertical"
      alignContent="start"
      className={css.trendingArticlesWrapper}
      dataHook={DATA_HOOKS.TRENDING_ARTICLES}
    >
      <Heading
        dataHook={DATA_HOOKS.TRENDING_ARTICLES_TITLE}
        className={css.trendingArticlesTitle}
        as='h2'
      >
        {t('homepage.trending-articles.title')}
      </Heading>
      <Text
        heading="H4"
        size="medium"
        weight="normal"
        dataHook={DATA_HOOKS.TRENDING_ARTICLES_DESCRIPTION}
        className={css.trendingArticlesDescription}
      >
        {t('homepage.trending-articles.description')}
      </Text>
      <Box
        direction="horizontal"
        alignContent="start"
        className={css.trendingArticlesContent}
      >
        <Box className={css.trendingArticlesBackground}>
          <Image
            src={TRENDING_ARTICLES_BACKGROUND}
            alt={t('homepage.trending-articles.background.alt')}
            fill
            sizes={`(max-width: ${MAX_MOBILE_WIDTH}px) 100px 200px, (min-width: ${(MAX_MOBILE_WIDTH + 1)}px) and (max-width: 1200px) 100% 375px`}
            loading="eager"
          />
        </Box>
        <Box
          direction="vertical"
          alignContent="start"
          className={css.trendingArticlesItems}
        >
          {limitedArticles.map((article, idx) => (
            <>
              {idx === 1 ? <div ref={itemRef} /> : null}
              <TrendingArticleItem
                key={article.id}
                label={categoriesPaths[article.categoryId][0]?.name}
                title={article.title}
                uri={article.uri}
                categoryUri={categoriesPaths[article.categoryId][0]?.uri}
                onArticleClick={() => onArticleClick(article, idx + 1)}
                onArticleCategoryClick={() =>
                  onArticleCategoryClick(
                    categoriesPaths[article.categoryId][0],
                    idx + 1
                  )
                }
              />
              {idx < limitedArticles.length - 1 ? (
                <Divider direction="horizontal" />
              ) : null}
            </>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
