import { Logger } from '@wix/wix-log';

const DEFAULT_NAMESPACE = 'kb-client-nextjs';

export enum LoggerNamespaces {
  Server = 'server',
  Article = 'article',
}

export const createLogger = (namespace: LoggerNamespaces): Logger =>
  new Logger([DEFAULT_NAMESPACE, namespace].join(':'));
