import {
  AppApiRequest,
  BootstrapContextServerNextjs,
  KbBlog,
} from '../../types';
import { getPostFeedPage } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/rpc';
import {
  FeedType,
  Post,
  GetPostFeedPageResponse,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import { createLogger, LoggerNamespaces } from '../../utils/logger';
import { isProduction } from '@wix/wix-run-mode';
import { postsFeedPageMockData } from '../../__mocks__/postsFeedPage';
import { withCache } from '../utils/cache';
import { Redis } from 'ioredis';
import { LOCALES, REDIS } from '../../constants';
import { isSledUserAgent } from '../../utils/userAgent';

const logger = createLogger(LoggerNamespaces.Server);

export const MAX_BLOG_POSTS = 20;
const emptyResultsForLocales = [
  LOCALES.DE,
  LOCALES.PT,
  LOCALES.JA,
  LOCALES.KO,
  LOCALES.TR,
];

export const fetchBlogs = async (
  context: BootstrapContextServerNextjs,
  req: AppApiRequest,
  locale: string,
  redisClient: Redis
) => {
  if (emptyResultsForLocales.find((l) => l === locale)) {
    return [];
  }
  try {
    const blogConf = getBlogMsidByLocale(locale);
    const blogs = await withCache(
      async () => {
        const signedAspects = await context.apiGwClient.addServiceIdentitySafe(
          req.aspects
        );
        const signedAspectsWithSite =
          await context.apiGwClient.addMetaSiteIdToRequest(
            signedAspects,
            blogConf.msid
          );

        const response = await context.ambassador.request(
          getPostFeedPage({
            locale,
            languageCode: locale,
            pageSize: MAX_BLOG_POSTS,
            page: 1,
            type: FeedType.CATEGORY,
            categoryOptions: {
              id: blogConf.catId,
            },
          }),
          signedAspectsWithSite
        );

        logger
          .withAspects(req.aspects)
          .info('response from getPostFeedPage', response);

        return simplifyBlogPostResponse(getRandomBlogs(response));
      },
      redisClient,
      `${REDIS.GET_BLOG_POSTS.KEY}-${blogConf.msid}`,
      REDIS.GET_BLOG_POSTS.TTL
    );

    const userAgent = req.headers['user-agent'];
    if (!isProduction() || (userAgent && isSledUserAgent(userAgent))) {
      return simplifyBlogPostResponse(
        postsFeedPageMockData.response.postFeedPage.posts.posts as any as Post[]
      );
    }
    return blogs;
  } catch (error) {
    logger
      .withAspects(req.aspects)
      .error('Error in getPostFeedPage ambassadpr request', error);

    return [];
  }
};

export const simplifyBlogPostResponse = (blogs: Post[]): KbBlog[] =>
  blogs.map((blog) => ({
    id: blog.id,
    url: `${blog.url?.base}${blog.url?.path}`,
    author: {
      name: blog.owner?.name,
      ownerUrl: `https://www.wix.com/blog/profile/${blog.owner?.slug}/author`,
    },
    title: blog.title,
    blogImageSrc:
      blog.coverMedia?.image?.url || blog.media?.wixMedia?.image?.url,
    lastPublishedDate: blog.lastPublishedDate,
  })) || [];

const getRandomIndexes = (blogsCount: number): number[] => {
  const blogsRandomCount = 4;
  const randomIndexes = new Set<number>();

  if (blogsCount < blogsRandomCount) {
    return Array(blogsCount)
      .fill(null)
      .map((_, i) => i);
  } else {
    while (randomIndexes.size < blogsRandomCount) {
      randomIndexes.add(Math.floor(Math.random() * blogsCount));
    }
    return Array.from(randomIndexes);
  }
};

const getRandomBlogs = (response: GetPostFeedPageResponse): Post[] => {
  const blogs = response.postFeedPage?.posts?.posts;
  if (!blogs?.length) {
    return [];
  }
  return getRandomIndexes(blogs?.length)?.map((index) => blogs[index]);
};

const getBlogMsidByLocale = (
  locale: string
): { msid: string; catId: string } => {
  switch (locale) {
    case LOCALES.FR:
      return {
        msid: '9e8865c2-f43c-48db-ae31-df65e4cec152',
        catId: '7e3d9399-4ffc-498a-9f3c-cb5ede88bf30',
      };
    case LOCALES.IT:
      return {
        msid: 'd6fcb006-dd82-4819-8e98-6d6c940b24fc',
        catId: 'd2085d35-8739-4c78-8358-30649bf25c66',
      };
    case LOCALES.ES:
      return {
        msid: 'd699b56e-d0ae-421a-a470-06d20b2615ff',
        catId: 'b4be1609-e3b4-45cf-b8a7-1ebfba140d61',
      };
    case LOCALES.EN:
    default:
      return {
        msid: 'a1dfb81a-98b4-4706-8b71-b54a77649faf',
        catId: 'd13eece9-de9a-4430-bdd2-8a2054696480',
      };
  }
};
