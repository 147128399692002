import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { Box, Heading, Text, TextButton } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';
import { ArrowRightSmall } from 'wix-ui-icons-common';
import {
  BI,
  TOOLTIP_ENTER_DELAY,
  WIX_STUDIO_COMMUNITY_FORUM_LINK,
} from '../../constants';
import { DATA_HOOKS } from '../../dataHooks';
import { useBI } from '../../hooks/useBI';
import { ItemOnPageEventSource, useBiOnVisibleItem } from '../../hooks/useBiOnVisibleItem';
import css from './index.module.scss';

export const StudioCommunityForumEntry: FunctionComponent = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();

  const { itemRef } = useBiOnVisibleItem(ItemOnPageEventSource.STUDIO_COMMUNITY_FORUM_ENTRY_HOME);

  const COMMUNITY_FORUM_ENTRY_BACKGROUND =
    'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/homepage/n-studio-community-forum-entry.jpg';

  const onClickHireAProfessional = async () => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.HOME,
        kb_lang: locale,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.WIX_STUDIO_COMMUNITY_FORUM,
        clicked_url: WIX_STUDIO_COMMUNITY_FORUM_LINK,
        clicked_text: t('homepage.community-forum-entry.cta-text'),
      })
    );
  };

  return (
    <Box
      direction="horizontal"
      align="space-between"
      className={css.studioCommunityForumEntryWrapper}
      dataHook={DATA_HOOKS.COMMUNITY_FORUM_ENTRY}
      gap="36px"
    >
      <Box
        direction="vertical"
        className={css.studioCommunityForumEntryContent}
      >
        <Heading
          size="large"
          className={css.studioCommunityForumEntryTitle}
          dataHook={DATA_HOOKS.COMMUNITY_FORUM_ENTRY_TITLE}
        >
          {t('homepage.community-forum-entry.title')}
        </Heading>
        <Box className={css.studioCommunityForumEntryImageMobileOnly}>
          <Image
            alt={t('homepage.community-forum-entry.title')}
            src={COMMUNITY_FORUM_ENTRY_BACKGROUND}
            sizes="(max-width: 1200px) 347px 211px"
            loading="lazy"
            fill
            quality={100}
          />
        </Box>
        <Text
          appearance="T3"
          weight="normal"
          className={css.studioCommunityForumEntryDescription}
          dataHook={DATA_HOOKS.COMMUNITY_FORUM_ENTRY_DESCRIPTION}
        >
          {t('homepage.community-forum-entry.description')}
        </Text>
        <Box direction='vertical' ref={itemRef}>
          <TextButton
            target="_blank"
            ellipsis
            tooltipProps={{ enterDelay: TOOLTIP_ENTER_DELAY }}
            as="a"
            skin="dark"
            onClick={onClickHireAProfessional}
            href={WIX_STUDIO_COMMUNITY_FORUM_LINK}
            className={css.studioCommunityForumEntryButton}
            dataHook={DATA_HOOKS.COMMUNITY_FORUM_ENTRY_BUTTON}
            suffixIcon={<ArrowRightSmall />}
            underline="always"
            weight="normal"
          >
            {t('homepage.community-forum-entry.cta-text')}
          </TextButton>
        </Box>
      </Box>
      <Box className={css.studioCommunityForumEntryImage}>
        <Image
          alt={t('homepage.community-forum-entry.background.alt')}
          src={COMMUNITY_FORUM_ENTRY_BACKGROUND}
          sizes="347px 211px"
          loading="lazy"
          fill
          quality={100}
        />
      </Box>
    </Box>
  );
};
