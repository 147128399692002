import { Box, Button, Heading, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';
import { BI, LOCALES } from '../../constants';
import { PageType } from '../../types';
import { mapPageTypeToSourceName } from '../../utils/bi';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { DATA_HOOKS } from '../../dataHooks';
import { useBI } from '../../hooks/useBI';
import { ItemOnPageEventSource, useBiOnVisibleItem } from '../../hooks/useBiOnVisibleItem';
import css from './index.module.scss';

export const RoadmapBanner: FunctionComponent = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();
  const { itemRef } = useBiOnVisibleItem(ItemOnPageEventSource.PRODUCT_ROADMAP);

  const goToRoadmap = async (): Promise<void> => {
    await sendBIEvent(
      pageClick({
        source_name: mapPageTypeToSourceName(PageType.Homepage),
        kb_lang: locale as string,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.INTERNAL_LINK,
        clicked_text: t('roadmap.banner.navigate-to-roadmap-button'),
        clicked_url: `${location.origin}/${LOCALES.EN}/roadmap`,
      })
    );
  };

  const showAvailableLanguagesDisclaimer = locale !== LOCALES.EN;

  return (
    <Box
      className={css.RoadmapBannerWrapper}
      dataHook={DATA_HOOKS.ROADMAP_BANNER}
    >
      <Box
        width="100%"
        verticalAlign={showAvailableLanguagesDisclaimer ? 'bottom' : 'middle'}
        direction="vertical"
        align="center"
        className={css.RoadmapBanner}
        paddingBottom={showAvailableLanguagesDisclaimer ? '24px' : undefined}
      >
        <Heading
          light
          textAlign="center"
          className={css.roadMapHeading}
          appearance={'H2'}
          dataHook={DATA_HOOKS.ROADMAP_BANNER_HEADING}
        >
          {t('roadmap.banner.heading')}
        </Heading>
        <Text
          light
          textAlign="center"
          className={css.roadmapDescription}
          weight="normal"
          color={'D10'}
          dataHook={DATA_HOOKS.ROADMAP_BANNER_SUBTITLE}
        >
          {t('roadmap.banner.sub-heading')}
        </Text>
        <Button
          as="a"
          priority="secondary"
          href={`/${LOCALES.EN}/roadmap`}
          target="_blank"
          onClick={goToRoadmap}
          skin="light"
          size="large"
          dataHook={DATA_HOOKS.ROADMAP_BANNER_CTA}
        >
          {t('roadmap.banner.navigate-to-roadmap-button')}
        </Button>
        <div ref={itemRef} />
        {showAvailableLanguagesDisclaimer ? (
          <Text
            className={css.SupportedLanguagesDisclaimer}
            size="small"
            weight="thin"
            light
            dataHook={DATA_HOOKS.ROADMAP_BANNER_SUPPORTED_LANGUAGES}
          >
            {t('roadmap.banner.supported-languages')}
          </Text>
        ) : null}
      </Box>
    </Box>
  );
};
