import { FunctionComponent, useMemo } from 'react';
import {
  Box,
  Heading,
  Image,
  Text,
  TextButton,
  Tooltip,
} from '@wix/design-system';
import css from './index.module.scss';
import { KbBlog } from '../../types';
import Link from 'next/link';
import moment from 'moment';
import { ArrowRight, ArrowLeft, InfoCircle } from 'wix-ui-icons-common';
import { useTranslation } from '@wix/wix-i18n-config';
import { DATA_HOOKS } from '../../dataHooks';
import { useRouter } from 'next/router';
import { isRTL } from '../../utils/internationalization';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { useBI } from '../../hooks/useBI';
import {
  ItemOnPageEventSource,
  useBiOnVisibleItem,
} from '../../hooks/useBiOnVisibleItem';
import { BI, LOCALES } from '../../constants';
import { MAX_BLOG_POSTS } from '../../services/wix-blogs';

export const supportedLocales = [LOCALES.EN, LOCALES.FR, LOCALES.ES, LOCALES.IT];

export const getExternalBlogLink = (locale: string) =>
  `https://${
    supportedLocales.find((l) => l === locale) ? locale : LOCALES.EN
  }.wix.com/blog`;

export type BlogsProps = {
  blogs: KbBlog[];
};

export const Blogs: FunctionComponent<BlogsProps> = ({ blogs }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();
  const isRTLLanguage = isRTL(locale);
  const blogSiteUrl = getExternalBlogLink(locale as string);

  const onGoToBlogClickEvent = () =>
    sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.HOME,
        kb_lang: locale as string,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.EXTERNAL_LINK,
        clicked_text: t('wix-blog.link-site'),
        clicked_url: blogSiteUrl,
      })
    );

  const blogPosts = useMemo(
    () =>
      blogs
        .slice(0, MAX_BLOG_POSTS)
        .map((blog, index) => (
          <BlogItemComp
            key={blog.id}
            blogData={blog}
            locale={locale}
            itemOrder={index + 1}
          />
        )),
    [blogs, locale]
  );

  return (
    <Box
      direction="vertical"
      alignContent="start"
      className={css.BlogsSectionWrapper}
      dataHook={DATA_HOOKS.BLOGS_SECTIONS}
    >
      <Heading
        dataHook={DATA_HOOKS.BLOGS_SECTION_HEADER}
        size="extraLarge"
        className={css.title}
        as="h2"
      >
        {t('wix-blog.header')}
      </Heading>
      <Box display="flex">
        <Text
          className={css.subtitle}
          dataHook={DATA_HOOKS.BLOGS_SECTION_SUBHEADER}
          heading="H4"
          size="medium"
          weight="normal"
        >
          {t('wix-blog.subheader')}
        </Text>
        {!supportedLocales.includes(locale as string) ? (
          <Tooltip
            dataHook={DATA_HOOKS.BLOGS_SECTION_NON_SUPPORTED_LANGUAGES_TOOLTIP}
            content={t('wix-blog.tooltip')}
          >
            <InfoCircle />
          </Tooltip>
        ) : null}
      </Box>
      <Box
        direction="horizontal"
        align="center"
        flexWrap="wrap"
        gap="SP5"
        className={css.BlogsSectionItems}
      >
        {blogPosts}
      </Box>
      <Box
        className={css.linkToBlogSite}
        dataHook={DATA_HOOKS.BLOGS_SECTION_EXTERNAL_LINK}
      >
        <TextButton
          size="medium"
          weight="normal"
          skin="dark"
          underline="always"
          suffixIcon={isRTLLanguage ? <ArrowLeft /> : <ArrowRight />}
          onClick={onGoToBlogClickEvent}
          href={blogSiteUrl}
          as="a"
          target="_blank"
        >
          {t('wix-blog.link-site')}
        </TextButton>
      </Box>
    </Box>
  );
};

type BlogItemCompProps = {
  blogData: KbBlog;
  locale?: string;
  itemOrder: number;
};

const BlogItemComp: FunctionComponent<BlogItemCompProps> = ({
  blogData,
  locale,
  itemOrder,
}) => {
  const { title, author, blogImageSrc, url, lastPublishedDate } = blogData;
  const { itemRef } = useBiOnVisibleItem(ItemOnPageEventSource.BLOG_SECTION);
  const { sendBIEvent } = useBI();

  const refOnlyOnFirstItem = itemOrder === 1 ? itemRef : null;

  const onBlogPostClickEvent = () =>
    sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.HOME,
        kb_lang: locale as string,
        clicked_item_order: `${itemOrder}`,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.BLOG_POST,
        clicked_text: title,
        clicked_url: url,
      })
    );

  const onBlogAuthorClickEvent = () =>
    sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.HOME,
        kb_lang: locale as string,
        clicked_item_order: `${itemOrder}`,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.BLOG_POST_AUTHOR,
        clicked_text: `${author.name}`,
        clicked_url: author.ownerUrl,
      })
    );

  return (
    <Box
      dataHook={DATA_HOOKS.BLOGS_SECTION_ITEM}
      className={css.blogItem}
      direction="vertical"
    >
      <Image
        dataHook={DATA_HOOKS.BLOGS_SECTION_ITEM_IMAGE}
        onClick={() => {
          void onBlogPostClickEvent();
          window.open(url);
        }}
        src={blogImageSrc}
        loading="lazy"
        alt=""
        borderRadius="12px"
      />
      <Link
        className={css.blogName}
        href={url || ''}
        onClick={onBlogPostClickEvent}
        target="_blank"
      >
        <Box ref={refOnlyOnFirstItem}>
          <Text
            dataHook={DATA_HOOKS.BLOGS_SECTION_ITEM_TITLE}
            maxLines={2}
            ellipsis
            weight="normal"
            size="medium"
          >
            {title}
          </Text>
        </Box>
      </Link>
      <Text
        dataHook={DATA_HOOKS.BLOGS_SECTION_ITEM_AUTHOR}
        className={css.authorName}
        size="tiny"
        weight="thin"
        onClick={onBlogAuthorClickEvent}
      >
        <Link href={author.ownerUrl || ''} target="_blank">
          {author.name}
        </Link>{' '}
        · {getFormatPublishBlogDate(lastPublishedDate)}
      </Text>
    </Box>
  );
};

export const getFormatPublishBlogDate = (publishDate?: Date) =>
  moment(publishDate).format('MMM D');
