import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { Box, Heading, Text, Tooltip } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { InfoCircle } from '@wix/wix-ui-icons-common';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';
import { BI } from '../../constants';
import { DATA_HOOKS } from '../../dataHooks';
import { useBI } from '../../hooks/useBI';
import { ExploreMoreResourcesItem } from './ExploreMoreResourcesItem';
import css from './index.module.scss';
export type ExploreMoreResourcesProps = {
  isNonEnglishLocale: boolean;
  isShowBlogResource: boolean;
};

export const EXPLORE_MORE_RESOURCES_ITEMS = [
  {
    translationKey: 'wix-learn',
    url: 'https://www.wix.com/learn',
  },
  {
    translationKey: 'wix-blog',
    url: 'https://www.wix.com/blog',
  },
  {
    translationKey: 'seo-learning-hub',
    url: 'https://www.wix.com/seo/learn',
  },
  {
    translationKey: 'velo',
    url: 'https://www.wix.com/velo',
  },
];

export const ExploreMoreResources: FunctionComponent<
  ExploreMoreResourcesProps
> = ({ isNonEnglishLocale, isShowBlogResource }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();

  const onResourceClick = async (
    resource: (typeof EXPLORE_MORE_RESOURCES_ITEMS)[0],
    itemOrder: number
  ) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.HOME,
        kb_lang: locale,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.MORE_RESOURCES,
        clicked_url: resource.url,
        clicked_text: t(
          `homepage.explore-more-resources.${resource.translationKey}.cta`
        ),
        clicked_item_order: `${itemOrder}`,
      })
    );
  };

  const resourcesItems = !isShowBlogResource
    ? EXPLORE_MORE_RESOURCES_ITEMS.filter(
        ({ translationKey }) => translationKey !== 'wix-blog'
      )
    : EXPLORE_MORE_RESOURCES_ITEMS;

  return (
    <Box
      direction="vertical"
      align="left"
      className={css.exploreMoreResourcesWrapper}
      dataHook={DATA_HOOKS.EXPLORE_MORE_RESOURCES}
    >
      <Heading
        dataHook={DATA_HOOKS.EXPLORE_MORE_RESOURCES_TITLE}
        className={css.exploreMoreResourcesTitle}
        as="h2"
      >
        {t('homepage.explore-more-resources.title')}
      </Heading>
      <Box
        align="center"
        className={css.exploreMoreResourcesDescriptionWrapper}
      >
        <Text
          heading="H4"
          size="medium"
          weight="normal"
          dataHook={DATA_HOOKS.EXPLORE_MORE_RESOURCES_DESCRIPTION}
          className={css.exploreMoreResourcesDescription}
        >
          {t('homepage.explore-more-resources.description')}
        </Text>
        {isNonEnglishLocale ? (
          <Tooltip
            dataHook={DATA_HOOKS.EXPLORE_MORE_RESOURCES_TOOLTIP}
            content={t('homepage.explore-more-resources.tooltip')}
          >
            <InfoCircle />
          </Tooltip>
        ) : null}
      </Box>
      <Box className={css.exploreMoreResourcesItemsWrapper}>
        {resourcesItems.map((resource, idx) => (
          <Box
            key={resource.translationKey}
            verticalAlign="top"
            className={classNames(
              css.exploreMoreResourcesItemWrapper,
              css.excludeBlogItem
            )}
          >
            <ExploreMoreResourcesItem
              title={t(
                `homepage.explore-more-resources.${resource.translationKey}.title`
              )}
              description={t(
                `homepage.explore-more-resources.${resource.translationKey}.description`
              )}
              url={resource.url}
              cta={t(
                `homepage.explore-more-resources.${resource.translationKey}.cta`
              )}
              onClick={() => onResourceClick(resource, idx + 1)}
              withOnViewEvent={idx === 0}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
