import { Badge, Box, Heading } from '@wix/design-system';
import { FunctionComponent } from 'react';
import { ArrowRight } from 'wix-ui-icons-common';
import { InnerLink } from '../../InnerLink';
import { DATA_HOOKS } from './../../../dataHooks';
import css from './index.module.scss';

export type TrendingArticleItemProps = {
  label: string;
  title: string;
  uri: string;
  categoryUri: string;
  onArticleClick: () => void;
  onArticleCategoryClick: () => void;
};

export const TrendingArticleItem: FunctionComponent<
  TrendingArticleItemProps
> = ({
  label,
  title,
  uri,
  categoryUri,
  onArticleClick,
  onArticleCategoryClick,
}) => (
  <Box
    direction="vertical"
    align="left"
    className={css.trendingArticleItemWrapper}
    dataHook={DATA_HOOKS.TRENDING_ARTICLES_ITEM}
  >
    <InnerLink
      href={categoryUri || ''}
      onClick={onArticleCategoryClick}
      className={css.trendingArticleItemLink}
    >
      <Badge
        size="tiny"
        skin="neutralLight"
        dataHook={DATA_HOOKS.TRENDING_ARTICLES_ITEM_LABEL}
        className={css.trendingArticleItemLabel}
      >
        {label}
      </Badge>
    </InnerLink>
    <InnerLink
      href={uri || ''}
      onClick={onArticleClick}
      className={css.trendingArticleItemLink}
      dataHook={DATA_HOOKS.TRENDING_ARTICLES_ITEM_TITLE}
    >
      <Box
        direction="horizontal"
        align="space-between"
        verticalAlign="bottom"
        className={css.trendingArticleItemTitleWrapper}
      >
        <Heading
          ellipsis
          appearance='H3'
          className={css.trendingArticleItemTitle}
        >
          {title}
        </Heading>
        <ArrowRight className={css.trendingArticleItemArrow} />
      </Box>
    </InnerLink>
  </Box>
);
