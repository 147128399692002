import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { Box, Heading, Text, TextButton } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';
import { ArrowRightSmall } from 'wix-ui-icons-common';
import { BI } from '../../constants';
import { DATA_HOOKS } from '../../dataHooks';
import { useBI } from '../../hooks/useBI';
import {
  ItemOnPageEventSource,
  useBiOnVisibleItem,
} from '../../hooks/useBiOnVisibleItem';
import css from './index.module.scss';

export const HIRE_A_PROFESSIONAL_LINK = 'https://www.wix.com/marketplace/brief/match?entry=editor-x&serviceType=1&innerType=1';

export const MarketplaceEntry: FunctionComponent = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();
  const { itemRef } = useBiOnVisibleItem(
    ItemOnPageEventSource.MARKETPLACE_ENTRY,
    {}
  );
  const MARKETPLACE_ENTRY_BACKGROUND =
    'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/homepage/n-hire-a-professional-entry.jpg';

  const onClickHireAProfessional = async () => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.HOME,
        kb_lang: locale,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.HIRE_A_WIX_PARTNER,
        clicked_url: HIRE_A_PROFESSIONAL_LINK,
        clicked_text: t('homepage.marketplace-entry.cta-text'),
      })
    );
  };

  return (
    <Box
      direction="horizontal"
      align="space-between"
      className={css.marketplaceEntryWrapper}
      dataHook={DATA_HOOKS.MARKETPLACE_ENTRY}
    >
      <Box direction="vertical" className={css.marketplaceContent}>
        <Heading
          size="large"
          className={css.marketplaceEntryTitle}
          dataHook={DATA_HOOKS.MARKETPLACE_ENTRY_TITLE}
        >
          {t('homepage.marketplace-entry.title')}
        </Heading>
        <Box className={css.marketplaceEntryImageMobileOnly}>
          <Image
            alt={t('homepage.marketplace-entry.title')}
            src={MARKETPLACE_ENTRY_BACKGROUND}
            sizes="(max-width: 1200px) 347px 211px"
            loading="lazy"
            quality={100}
            fill
          />
        </Box>
        <Text
          appearance="T3"
          className={css.marketplaceEntryDescription}
          weight="normal"
          dataHook={DATA_HOOKS.MARKETPLACE_ENTRY_DESCRIPTION}
        >
          {t('homepage.marketplace-entry.description')}
        </Text>
        <Box direction='vertical' ref={itemRef}>
          <TextButton
            target="_blank"
            as="a"
            skin="dark"
            onClick={onClickHireAProfessional}
            href={HIRE_A_PROFESSIONAL_LINK}
            className={css.marketplaceButton}
            dataHook={DATA_HOOKS.MARKETPLACE_ENTRY_BUTTON}
            suffixIcon={<ArrowRightSmall />}
            underline="always"
            weight="normal"
          >
            {t('homepage.marketplace-entry.cta-text')}
          </TextButton>
        </Box>
      </Box>
      <Box className={css.marketplaceEntryImage}>
        <Image
          alt={t('homepage.marketplace-entry.background.alt')}
          src={MARKETPLACE_ENTRY_BACKGROUND}
          sizes="(max-width: 1200px) 347px 211px"
          quality={100}
          fill
        />
      </Box>
    </Box>
  );
};
